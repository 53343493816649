import "./styles.styl";

import bulletImg from "./images/bullet.jpg";
import bulletMobileImg from "./images/bullet-mobile.png";
import unchartedImg from "./images/uncharted.jpg";
import unchartedMobileImg from "./images/uncharted-mobile.png";
import bulletVid from "./videos/bullet.mp4";
import unchartedVid from "./videos/uncharted.mp4";

document.addEventListener("DOMContentLoaded", function() {
  var isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
  console.log(isMobile)
  var videos = [unchartedVid, bulletVid];
  let images = [];
  images = isMobile ? [unchartedMobileImg, bulletMobileImg] : [unchartedImg, bulletImg];
  var currentIndex = 0; // Start at the first slide

  var videoElement = document.getElementById('videoElement');
  var imageElement = isMobile ? document.getElementById('imageMobileElement') : document.getElementById('imageElement');
  var leftArrow = isMobile ? document.getElementById('leftMobileArrow') : document.getElementById('leftArrow');
  var rightArrow = isMobile ? document.getElementById('rightMobileArrow') : document.getElementById('rightArrow');

  function updateSlide(index) {
    videoElement.src = videos[index];
    imageElement.src = images[index];
  }

  leftArrow.addEventListener('click', function() {
    if (currentIndex > 0) {
      currentIndex--;
    } else {
      currentIndex = videos.length - 1; // Loop back to the last slide
    }
    updateSlide(currentIndex);
  });

  rightArrow.addEventListener('click', function() {
    if (currentIndex < videos.length - 1) {
      currentIndex++;
    } else {
      currentIndex = 0; // Loop back to the first slide
    }
    updateSlide(currentIndex);
  });
});
